import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearOrders,
  parseAndProcessCSVFile,
  setError,
  setLoader,
  setSolutionJson,
  setTourParameter,
  setUploadedFile,
} from 'actions';
import withTranslation from 'hoc/withTranslation';
import { first, isEmpty, keys, size } from 'lodash';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import { useCookies } from 'react-cookie';
import {
  StyledWizardContent,
  StyledWizardH1,
  StyledIcon,
  StyledFullWidthContainer,
  StyledDoublePanel,
  StyledText,
  StyledLine,
  JobForTitleIconStyle,
  StyledLink,
} from '../Global/WizardStyled';
import { canDownloadCSV, getAsset, isMobileDevice, isProdEnv } from '../../../utils/helpers';
import Section from '../../Form/Section';
import downloadIcon from '../../../global/img/download.svg';
import trashIcon from '../../../global/img/trash.svg';
import trashIconHover from '../../../global/img/trash_hover.svg';
import iconSettings from '../../../global/img/settings.svg';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../../utils/amplitude';
import Selector from '../../Form/Selector';
import { APP_MODES, getAppMode } from '../../../utils/urlHelpers';
import Label from '../../Form/Label';
import OrdersDemo from './OrdersDemo';
import OrdersManual from './OrdersManual';
import OrdersUrl from './OrdersUrl';
import { getOrdersFromDiscoverItems } from '../../../utils/GeoCoder';
import { saveCSVTemplate } from '../../../utils/CSVTemplateExport';
import OrdersNotLocated from './OrdersNotLocated';
import OrdersCSVConfig from './OrdersCSVConfig';
import { getInitialFieldsConfig, isCSVUnderstood } from '../../../utils/csv/ManualConfigHelpers';
import { saveOrdersToCSVFile } from '../../../utils/SolutionExportToCsv';
import { getCSVHeaders } from '../../../utils/csv/parser';
import { getSafeValue } from '../../../utils/security';
import config from '../../../config';
import Orders from './Orders';
import FileUploadArea from './FileUploadArea';

const { primaryTextColor, primaryColor, primaryColorHovered, secondaryTextColor } = colors;
const { small } = fontSize;
const {
  documentationLinks: { documentationApiKeyURL, documentationTP },
} = config;
const appMode = getAppMode();
const isProd = isProdEnv();

const StyledRemoveLink = styled.div({
  display: 'inline-block',
  cursor: 'pointer',
  div: {
    marginLeft: '2rem',
    transition: 'all 500ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  },
  transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  '&:hover': {
    div: {
      backgroundImage: `url(${getAsset(trashIconHover)})`,
    },
  },
});

const StyledLabelLeft = styled.div({
  marginBottom: '1rem',
  color: primaryTextColor,
  fontSize: small,
  textAlign: 'left',
  width: '300px',
  lineHeight: '1rem',
  '> div': {
    textTransform: 'uppercase',
    cursor: 'pointer',
    div: {
      float: 'right',
    },
    marginTop: '6px',
    fontWeight: '700',
    padding: '7px 9px 6px 12px',
    display: 'inline-block',
    width: '120px',
    borderRadius: '50px',
    border: `solid 1px ${primaryColorHovered} !important`,
    boxShadow: `inset 0px 0px 0px .1px ${primaryColor} !important`,
    color: secondaryTextColor,
    '&:hover': {
      border: `solid 1px ${primaryColor} !important`,
      boxShadow: `inset 0px 0px 0px 1px ${primaryColor} !important`,
    },
  },
});

const DownloadIconStyle = css`
  background-image: url(${getAsset(downloadIcon)});
  width: 1rem !important;
  height: 1rem !important;
  background-size: 0.85rem !important;
  background-position-y: 0px;
`;

const RemoveAllIconStyle = css`
  background-image: url(${getAsset(trashIcon)});
  background-position: center right;
  background-size: 1.1rem;
  width: 1.2rem;
  height: 1.2rem;
`;

const StyledEmptyOrders = styled.div({
  height: '100%',
  padding: '4rem 0 10rem 0',
  textAlign: 'center',
  div: {
    textAlign: 'center',
    margin: 'auto',
    fontWeight: 400,
    color: primaryTextColor,
  },
});

const StyledImportSettingsContainer = styled.div({
  textAlign: 'right',
  fontSize: small,
  color: secondaryTextColor,
  margin: '-1.2rem 0 0 0',
  lineHeight: '1rem',
  cursor: 'pointer',
  textTransform: 'uppercase',
  fontWeight: 500,
  div: {
    position: 'relative',
    top: '2px',
  },
});

const StyledDownloadOrdersContainer = styled.div({
  cursor: 'pointer',
  display: 'inline-block',
});

const Step2Container = ({
  oAuth,
  user,
  orders,
  ordersNotLocated,
  errorState,
  tourPlanner,
  uploadedFile,
  onConfigToggle,
  setIsDemoMode,
  solution,
  translations: {
    wizard: {
      download,
      orders: {
        ordersTitle,
        templateLabel,
        removeAll,
        ordersAvailable,
        modesTitle,
        modes: { importTrans, manualTrans, demoTrans },
        emptyOrders,
        importSettings,
      },
      apiKeyHint,
      apiKeyDocumentationLink,
      hintEvaluationAgreement,
      contactUsLink,
      hintToUseDemo,
    },
    csv,
    error,
  },
}) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(['apikey']);
  const handleSetLoader = useCallback((data) => dispatch(setLoader(data)), [dispatch]);
  const handleSetTourParameter = useCallback((p) => dispatch(setTourParameter(p)), [dispatch]);
  const handleSetFile = useCallback((data) => dispatch(setUploadedFile(data)), [dispatch]);
  const handleProcessCSVFile = useCallback(
    (data, mode) => dispatch(parseAndProcessCSVFile(data, error, orders, mode)),
    [dispatch, error, orders],
  );
  const handleClearOrder = useCallback((data) => dispatch(clearOrders(data)), [dispatch]);
  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );
  const solutionJson = useSelector((state) => state.solution).json;

  const handleClearOrders = useCallback(() => {
    handleClearOrder({ index: solution.show });
    handleSetSolutionJson({ jsonTriggerVRP: true });
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_DELETE_ALL);
    if (tourPlanner.ordersMode === 'demo') {
      const ordersMode = isMobileDevice() ? 'manual' : 'import';
      handleSetTourParameter({ ordersMode, index: solution.show });
    }
  }, [tourPlanner, handleSetTourParameter, handleSetSolutionJson, handleClearOrder, solution.show]);
  const [requiresCSVConfig, setRequiresCSVConfig] = useState(false);

  useEffect(() => {
    onConfigToggle(requiresCSVConfig);
  }, [requiresCSVConfig, onConfigToggle]);

  const onCloseCSVConfig = useCallback(() => {
    setRequiresCSVConfig(false);
  }, [setRequiresCSVConfig]);

  const isUnderstood = useCallback(
    (file) => {
      return isCSVUnderstood(file, tourPlanner, user, csv);
    },
    [tourPlanner, user, csv],
  );

  const initiateCSVConfig = useCallback(
    (file) => {
      if (!isEmpty(tourPlanner.csvConfig)) return;

      getCSVHeaders(file).then((headers) => {
        const csvConfig = getInitialFieldsConfig(headers, tourPlanner, user, csv);
        const demandLabel = keys(csvConfig).filter(
          (key) => getSafeValue(csvConfig, key) === 'demand',
        );
        handleSetTourParameter({ csvConfig, lastDemandLabel: first(demandLabel) });
      });
    },
    [tourPlanner, user, csv],
  );

  useEffect(() => {
    if (!uploadedFile) return;

    isUnderstood(uploadedFile.file)
      .then((isClear) => {
        if (isClear) {
          initiateCSVConfig(uploadedFile.file);
          handleProcessCSVFile(uploadedFile.file, uploadedFile.mode);
          handleSetFile();
        } else {
          setRequiresCSVConfig(true);
        }
      })
      .catch(() => {
        handleSetFile();
        dispatch(setError(error.invalidFileFormat));
      });
  }, [uploadedFile, handleProcessCSVFile, handleSetFile, setRequiresCSVConfig, isUnderstood]);

  const ordersModes = useMemo(
    () => [
      { key: 'import', value: importTrans },
      { key: 'manual', value: manualTrans },
      { key: 'demo', value: demoTrans },
    ],
    [importTrans, manualTrans, demoTrans],
  );

  const ordersModesUX = useMemo(
    () => [
      { key: 'import', value: importTrans },
      { key: 'manual', value: manualTrans },
      { key: 'url', value: 'Url' },
      { key: 'demo', value: demoTrans },
    ],
    [importTrans, manualTrans, demoTrans],
  );

  const onOrdersModeChange = useCallback(
    (ordersMode) => {
      handleSetTourParameter({ ordersMode, editedOrder: {}, index: solution.show });
    },
    [handleSetTourParameter, solution.show],
  );

  useEffect(() => {
    if (tourPlanner.ordersMode === 'demo' && errorState) {
      const ordersMode = isMobileDevice() ? 'manual' : 'import';
      handleSetTourParameter({ ordersMode });
    }
  }, [errorState, tourPlanner.ordersMode, handleSetTourParameter]);

  const onDownloadTemplate = useCallback(() => {
    handleSetLoader({ isLoading: true });
    getOrdersFromDiscoverItems('restaurant', oAuth, user, tourPlanner).then((items) => {
      saveCSVTemplate(items);
      handleSetLoader({ isLoading: false });
    });
  }, [oAuth, user, tourPlanner, handleSetLoader]);

  const onClickSettings = useCallback(() => {
    setRequiresCSVConfig(true);
  }, [setRequiresCSVConfig]);

  const onDownloadOrders = useCallback(() => {
    saveOrdersToCSVFile(orders, user, 'Orders2Plan -');
  }, [orders, user]);

  const showTemplate = canDownloadCSV();

  const disabledIndexes = (() => {
    if (!isEmpty(orders) || !oAuth) return !appMode.includes(APP_MODES.UX) ? [2] : [3];
    return [];
  })();

  const hasApiKey = isProd && cookies.apikey && cookies.apikey !== '';

  useEffect(() => {
    setIsDemoMode(tourPlanner.ordersMode === 'demo');
  }, [setIsDemoMode, tourPlanner.ordersMode]);

  useEffect(() => {
    if (tourPlanner.ordersMode !== 'demo' && isProd && !hasApiKey)
      handleClearOrder({ index: solution.show });
  }, [tourPlanner.ordersMode, hasApiKey, solution.show, handleClearOrder]);

  return (
    <StyledWizardContent>
      {requiresCSVConfig ? (
        <OrdersCSVConfig
          uploadedFile={uploadedFile}
          tourPlanner={tourPlanner}
          orders={orders}
          onClose={onCloseCSVConfig}
          oAuth={oAuth}
          user={user}
        />
      ) : (
        <>
          {!solutionJson ? (
            <>
              <StyledWizardH1>{ordersTitle}</StyledWizardH1>
              <Section>
                <Label>{modesTitle}</Label>
                <Selector
                  id="button-orders-mode"
                  initialValue={tourPlanner.ordersMode}
                  options={appMode.includes(APP_MODES.UX) ? ordersModesUX : ordersModes}
                  onChange={onOrdersModeChange}
                  disabledIndexes={disabledIndexes}
                />
              </Section>
              {isProd &&
                (tourPlanner.ordersMode === 'import' || tourPlanner.ordersMode === 'manual') &&
                !hasApiKey && (
                  <Section>
                    <StyledLine />
                    <StyledText>
                      {apiKeyHint}
                      <StyledLink
                        contrast
                        target="_blank"
                        rel="noopener noreferrer"
                        href={documentationApiKeyURL}
                      >
                        {apiKeyDocumentationLink}
                      </StyledLink>
                      {hintEvaluationAgreement}
                      <StyledLink
                        contrast
                        target="_blank"
                        rel="noopener noreferrer"
                        href={documentationTP}
                      >
                        {contactUsLink}
                      </StyledLink>
                      {hintToUseDemo}
                    </StyledText>
                  </Section>
                )}
              {(tourPlanner.ordersMode === 'import' || tourPlanner.ordersMode === 'json_import') &&
                (hasApiKey || !isProd) && (
                  <Section>
                    {isEmpty(ordersNotLocated) && (
                      <>
                        {showTemplate && (hasApiKey || !isProd) && (
                          <StyledLabelLeft hasOrders={!isEmpty(orders)}>
                            {templateLabel}
                            <br />
                            <div id="link-template-download" onClick={onDownloadTemplate}>
                              {download}
                              <StyledIcon icon={DownloadIconStyle} mini />
                            </div>
                          </StyledLabelLeft>
                        )}
                        {user && user.cookies.accept && !isEmpty(tourPlanner.csvConfig) && (
                          <StyledImportSettingsContainer
                            data-test-id="importSetting"
                            onClick={onClickSettings}
                          >
                            {importSettings}&nbsp;
                            <StyledIcon iconUrl={iconSettings} />
                          </StyledImportSettingsContainer>
                        )}
                        <FileUploadArea
                          orders={orders}
                          errorState={errorState}
                          oAuth={oAuth}
                          user={user}
                          tourPlanner={tourPlanner}
                          hasApiKey={hasApiKey}
                          isPrdEnv={isProd}
                        />
                      </>
                    )}
                    {!isEmpty(ordersNotLocated) && (
                      <OrdersNotLocated ordersNotLocated={ordersNotLocated} user={user} />
                    )}
                  </Section>
                )}
              {tourPlanner.ordersMode === 'demo' && (
                <OrdersDemo orders={orders} oAuth={oAuth} user={user} tourPlanner={tourPlanner} />
              )}
              {tourPlanner.ordersMode === 'manual' && (hasApiKey || !isProd) && (
                <OrdersManual
                  oAuth={oAuth}
                  user={user}
                  tourPlanner={tourPlanner}
                  orders={orders}
                  solution={solution}
                />
              )}
              {tourPlanner.ordersMode === 'url' && (
                <OrdersUrl orders={orders} errorState={errorState} />
              )}
              {!isEmpty(orders) && (
                <>
                  <StyledLine />
                  <Section>
                    <StyledDoublePanel extremes useMax marginTop>
                      <StyledText id="label-orders-info">
                        <StyledIcon icon={JobForTitleIconStyle} />
                        {`${ordersAvailable}: ${size(orders)}`}
                      </StyledText>
                      <div>
                        {canDownloadCSV() && tourPlanner.ordersMode === 'manual' && (
                          <StyledDownloadOrdersContainer
                            title={download}
                            onClick={onDownloadOrders}
                          >
                            <StyledIcon icon={DownloadIconStyle} />
                          </StyledDownloadOrdersContainer>
                        )}
                        <StyledRemoveLink
                          id="link-remove-orders"
                          onClick={handleClearOrders}
                          title={removeAll}
                        >
                          <StyledIcon icon={RemoveAllIconStyle} />
                        </StyledRemoveLink>
                      </div>
                    </StyledDoublePanel>
                  </Section>
                  <StyledFullWidthContainer>
                    <Orders orders={orders} tourPlanner={tourPlanner} allowRemove />
                  </StyledFullWidthContainer>
                </>
              )}
              {isEmpty(orders) && tourPlanner.ordersMode === 'manual' && (hasApiKey || !isProd) && (
                <>
                  <StyledLine />
                  <Section>
                    <StyledFullWidthContainer>
                      <StyledEmptyOrders>
                        <StyledText>{emptyOrders}</StyledText>
                      </StyledEmptyOrders>
                    </StyledFullWidthContainer>
                  </Section>
                </>
              )}
            </>
          ) : (
            <>
              <Section>
                <StyledDoublePanel extremes useMax marginTop>
                  <StyledText id="label-orders-info">
                    <StyledIcon icon={JobForTitleIconStyle} />
                    {`${ordersAvailable}: `}
                    {isEmpty(orders) ? 'None' : `${size(orders)}`}
                  </StyledText>
                  <div>
                    {canDownloadCSV() && tourPlanner.ordersMode === 'manual' && (
                      <StyledDownloadOrdersContainer title={download} onClick={onDownloadOrders}>
                        <StyledIcon icon={DownloadIconStyle} />
                      </StyledDownloadOrdersContainer>
                    )}
                    {!solutionJson && (
                      <StyledRemoveLink
                        id="link-remove-orders"
                        onClick={handleClearOrders}
                        title={removeAll}
                      >
                        <StyledIcon icon={RemoveAllIconStyle} />
                      </StyledRemoveLink>
                    )}
                  </div>
                </StyledDoublePanel>
              </Section>
              <StyledFullWidthContainer>
                <Orders orders={orders} tourPlanner={tourPlanner} allowRemove hasJsonOrders />
              </StyledFullWidthContainer>
            </>
          )}
        </>
      )}
    </StyledWizardContent>
  );
};

export default withTranslation(Step2Container);
