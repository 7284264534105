import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import withTranslation from 'hoc/withTranslation';
import { css } from '@emotion/react';
import { useCookies } from 'react-cookie';
import informationWhiteIcon from '../../../global/img/info-white.svg';
import HereLogo from '../../Presentation/HereLogo';
import close from '../../../global/img/close.svg';
import LanguageSelector from './LanguageSelector';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import globe from '../../../global/img/globe.svg';
import info from '../../../global/img/info-green.svg';
import shakeHands from '../../../global/img/shake-hands.svg';
import mail from '../../../global/img/contact.svg';
import lock from '../../../global/img/lock.svg';
import MenuItemHeader from './MenuItemHeader';
import { colors } from '../../../global/variables';
import { setTourParameter, setUserCookies, setUserParam } from '../../../actions';
import CurrencySelector from './CurrencySelector';
import DistanceSelector from './DistanceSelector';
import { StyledIcon, StyledSpace, StyledTextInput } from '../../Wizard/Global/WizardStyled';
import { getAsset, isProdEnv } from '../../../utils/helpers';
import config from '../../../config';
import TpaSelector from './TpaSelector';
import RequestTypeSelector from './RequestTypeSelector';
import { getDefaultObjectiveFunctions } from '../../../utils/solverConfig/config';
import { hasOngoingAsyncRequest } from '../../../utils/SolutionHelpers';

const {
  documentationLinks: {
    documentationApiKeyURL,
    documentationTP,
    documentationRequestURL,
    documentationDevGuide,
  },
} = config;
const isProd = isProdEnv();

const StyledMenu = styled.div(({ isOpen }) => ({
  position: 'fixed',
  top: '0',
  bottom: '0',
  backgroundColor: 'rgb(50, 58, 70)',
  color: colors.secondaryTextColorDarkBg,
  width: '20rem',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 10103,
  transitionProperty: 'transform',
  transitionDuration: '0.5s',
  transitionTimingFunction: 'cubic-bezier(0.635, 0.000, 0.000, 1.000)',
  '@media screen and (max-width: 850px)': {
    width: '80%',
    left: '0',
    transform: isOpen ? '0' : 'translateX(-100%)',
  },
  '@media screen and (min-width: 851px)': {
    right: '0',
    transform: isOpen ? '0' : 'translateX(100%)',
  },
}));

const StyledMenuBackdrop = styled.div(({ isOpen }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: isOpen ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0)',
  backdropFilter: `blur(${isOpen ? '4px' : '0'})`,
  zIndex: 10100,
  visibility: isOpen ? 'visible' : 'hidden',
  transitionProperty: 'background-color backdrop-filter',
  transitionDuration: '0.5s',
  transitionTimingFunction: 'cubic-bezier(0.635, 0.000, 0.000, 1.000)',
}));

const StyledHeader = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexShrink: 0,
  height: '3rem',
  padding: '0 1rem',
  '@media screen and (max-width: 850px)': {
    justifyContent: 'space-between',
  },
});

const StyledBody = styled.div({
  overflowY: 'auto',
  height: '100%',
});

export const StyledCloseButton = styled.button(({ menuCloseBtn }) => ({
  position: 'absolute',
  zIndex: 101,
  right: 0,
  left: menuCloseBtn && 0,
  margin: '0.5rem',
  backgroundColor: 'transparent',
  backgroundImage: `url(${close})`,
  backgroundRepeat: 'no-repeat',
  border: 'none',
  opacity: 0.8,
  width: '1.5rem',
  height: '1.5rem',
  cursor: 'pointer',

  '&:hover': {
    opacity: 1,
  },
}));

const StyledInfoIcon = styled.div({
  backgroundImage: `url(${info})`,
  backgroundRepeat: 'no-repeat',
  width: '1.5rem',
  height: '1.5rem',
  position: 'relative',
  backgroundSize: '1.2rem',
  // Compensate visual center of mass shift
  top: '-0.1rem',
});

const StyledLanguageIcon = styled.div({
  backgroundImage: `url(${globe})`,
  backgroundRepeat: 'no-repeat',
  width: '1.5rem',
  height: '1.5rem',
});

const StyledShakeHandsIcon = styled.div({
  backgroundImage: `url(${shakeHands})`,
  backgroundRepeat: 'no-repeat',
  width: '1.5rem',
  height: '1.5rem',
  position: 'relative',
  // Compensate visual center of mass shift
  top: '-0.1rem',
});

const StyledMailIcon = styled.div({
  backgroundImage: `url(${mail})`,
  backgroundRepeat: 'no-repeat',
  width: '1.5rem',
  height: '1.5rem',
});

const StyledLockIcon = styled.div({
  backgroundImage: `url(${lock})`,
  backgroundRepeat: 'no-repeat',
  width: '1.5rem',
  height: '1.5rem',
  position: 'relative',
  left: '0.25rem',
});

const StyledMenuItem = styled.div({
  padding: '1.2rem',

  '& + &': {
    borderTop: '1px solid rgba(0,0,0,0.2)',
  },

  a: {
    textDecoration: 'none',
  },
});

const StyledSelectLabel = styled.div({
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  marginBottom: '0.3rem',
});

const StyledCookiesBody = styled.div({
  marginBottom: '0.5rem',
  lineHeight: '1.1rem',
  fontSize: '.8rem',
});

const StyledLink = styled.a(({ insideText }) => ({
  color: '#01b6b2',
  textDecorationLine: 'none',
  marginBottom: insideText ? '0' : '1.2rem',
  display: 'inline-block',
  ':hover': {
    color: '#00a39f',
    textDecorationLine: 'underline',
  },
}));

const StyledAdaptableContainer = styled.div({
  display: 'none',
  '@media screen and (max-width: 850px)': {
    display: 'block',
  },
});

const StyledSwitchContainer = styled.div({
  marginTop: '1rem',
});

export const StyledApiKeyInformationIcon = css`
  background-image: url(${getAsset(informationWhiteIcon)});
  cursor: pointer;
`;

const Menu = ({ user, translations, isOpen, onClickClose, linkLang, solution }) => {
  const dispatch = useDispatch();
  const tourPlanner = useSelector(
    ({ tourPlanner: stateTourPlanner }) => stateTourPlanner.value[solution.show],
  );
  const handleSetUserCoookies = useCallback((data) => dispatch(setUserCookies(data)), [dispatch]);
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);
  const handleSetTourParameter = useCallback(
    (parameter) => dispatch(setTourParameter(parameter)),
    [dispatch],
  );
  const [cookies, setCookie] = useCookies(['apikey']);
  const [apiKey, setApiKey] = useState('');
  const [showApiKeyInformation, setShowApiKeyInformation] = useState(false);
  const [showRequestTypeInformation, setShowRequestTypeInformation] = useState(false);
  const [showTPAVersionInformation, setShowTPAVersionInformation] = useState(false);
  const [disableRequestType, setDisableRequestType] = useState(false);

  const onClickHelp = () => {
    AmplitudeService.log(AMPLITUDE_EVENTS.CLICK_LINK, { link: 'Help menu' });
  };

  const setCookieParam = useCallback(
    (value) => {
      AmplitudeService.setOptIn(value);
      handleSetUserCoookies(value);
      if (value) {
        AmplitudeService.setFleetConfiguration(tourPlanner);
        AmplitudeService.setUserParams(user);
      }
    },
    [handleSetUserCoookies, tourPlanner, user],
  );

  const handleSwitch = useCallback(
    (e) => {
      const approved = e.target.checked;
      setCookieParam(approved);
    },
    [setCookieParam],
  );

  const handleSwitchStateMemory = useCallback(
    (e) => {
      const hasStateMemory = e.target.checked;
      handleSetUserParam({ hasStateMemory });
    },
    [handleSetUserParam],
  );

  const handleSwitchAccessLogging = useCallback(
    (e) => {
      const accessLogging = e.target.checked;
      handleSetUserParam({ accessLogging });
    },
    [handleSetUserParam],
  );

  const handleApiKeyChange = useCallback((e) => {
    setApiKey(e.target.value);
    setCookie('apikey', e.target.value, user.cookies.accept ? { maxAge: 365 * 24 * 60 * 60 } : {});
  }, []);

  useEffect(() => {
    const newObjectiveFunctionsValue = {
      enabled: false,
      values: getDefaultObjectiveFunctions(user.tpaVersion.version === 'beta'),
    };
    handleSetTourParameter({
      solverConfiguration: {
        objectiveFunctions: newObjectiveFunctionsValue,
        terminationCriteria: tourPlanner.solverConfiguration.terminationCriteria,
      },
    });
  }, [user.tpaVersion]);

  useEffect(() => {
    setApiKey(cookies.apikey);
  }, [cookies.apikey]);

  useEffect(() => {
    setDisableRequestType(hasOngoingAsyncRequest(solution.requests));
  }, [solution.requests]);

  const onFocus = (e) => {
    if (e.target instanceof HTMLInputElement) e.target.removeAttribute('readonly');
  };

  const handleTpaVersionCustom = useCallback(
    (e) => {
      handleSetUserParam({ tpaVersion: { version: 'custom', url: e.target.value } });
    },
    [handleSetUserParam],
  );

  return (
    <>
      <StyledAdaptableContainer>
        <StyledMenuBackdrop isOpen={isOpen} />
      </StyledAdaptableContainer>
      <StyledMenu isOpen={isOpen}>
        <StyledHeader>
          <StyledAdaptableContainer>
            <HereLogo backgroundTheme="dark" />
          </StyledAdaptableContainer>
          <StyledCloseButton data-testid="closeBtn" onClick={onClickClose} menuCloseBtn />
        </StyledHeader>

        <StyledBody data-test-id="menuPanelContent">
          <StyledMenuItem>
            <a href={documentationDevGuide} rel="noopener noreferrer" target="_blank">
              <MenuItemHeader
                icon={<StyledInfoIcon />}
                title={translations.menu.infoTitle}
                description={translations.menu.infoDescription}
              />
            </a>
          </StyledMenuItem>
          <StyledMenuItem>
            <MenuItemHeader icon={<StyledLockIcon />} title={translations.menu.keyLabel} />
            {isProd && (
              <div>
                <StyledSelectLabel>
                  {translations.menu.enterApiKey}
                  <StyledIcon
                    icon={StyledApiKeyInformationIcon}
                    onClick={() => setShowApiKeyInformation(!showApiKeyInformation)}
                    mini
                    margin="0 0 0 .5rem"
                  />
                </StyledSelectLabel>
                {showApiKeyInformation && (
                  <StyledCookiesBody>
                    {translations.menu.apiKeyText1}
                    <StyledLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href={documentationApiKeyURL}
                      insideText
                    >
                      {translations.menu.apiKeyLink1}
                    </StyledLink>
                    {translations.menu.apiKeyText2}
                  </StyledCookiesBody>
                )}
                <StyledTextInput
                  id="input-api-key"
                  placeholder={translations.menu.keyPlaceholder}
                  autoComplete="off"
                  theme="contrast"
                  defaultValue={apiKey}
                  onChange={handleApiKeyChange}
                  spellCheck={false}
                  password
                  readOnly
                  type="new-password"
                  onFocus={onFocus}
                />
              </div>
            )}
            <StyledSpace />
            <StyledSelectLabel>
              {translations.menu.selectTpa}
              {user.tpaVersion.version === 'beta' && isProd && (
                <StyledIcon
                  icon={StyledApiKeyInformationIcon}
                  onClick={() => setShowTPAVersionInformation(!showTPAVersionInformation)}
                  mini
                  margin="0 0 0 .5rem"
                />
              )}
            </StyledSelectLabel>
            {showTPAVersionInformation && user.tpaVersion.version === 'beta' && isProd && (
              <StyledCookiesBody>
                {translations.menu.tpaEvaluationAgreement}
                <StyledLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href={documentationTP}
                  insideText
                >
                  {translations.menu.tpaContactUsLink}
                </StyledLink>
                {translations.menu.tpaContactUsText}
              </StyledCookiesBody>
            )}
            <TpaSelector user={user} disabled={disableRequestType} />
            {user.tpaVersion.version === 'custom' && (
              <>
                <StyledSpace />
                <StyledTextInput
                  id="input-custom-env"
                  placeholder="http://localhost:3000/"
                  autoComplete="off"
                  theme="contrast"
                  defaultValue={user.tpaVersion.url}
                  onChange={handleTpaVersionCustom}
                  spellCheck={false}
                  inputMode="url"
                  type="url"
                  required
                />
              </>
            )}
            <>
              <StyledSpace />
              <StyledSelectLabel>
                {translations.menu.requestType}
                <StyledIcon
                  icon={StyledApiKeyInformationIcon}
                  onClick={() => setShowRequestTypeInformation(!showRequestTypeInformation)}
                  mini
                  margin="0 0 0 .5rem"
                />
              </StyledSelectLabel>
              {showRequestTypeInformation && (
                <StyledCookiesBody>
                  {translations.menu.requestTypeInformation}
                  <StyledLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={documentationRequestURL}
                    insideText
                  >
                    {translations.menu.requestTypeLink}
                  </StyledLink>
                </StyledCookiesBody>
              )}
              <RequestTypeSelector user={user} disabled={disableRequestType} />
            </>
          </StyledMenuItem>
          <StyledMenuItem>
            <MenuItemHeader
              icon={<StyledLanguageIcon />}
              title={translations.menu.regionalSettings}
            />
            <div hidden>
              <StyledSelectLabel>{translations.menu.selectLabel}</StyledSelectLabel>
              <LanguageSelector user={user} />
            </div>
            <StyledSpace />
            <StyledSelectLabel>{translations.menu.selectCurrency}</StyledSelectLabel>
            <CurrencySelector user={user} />
            <StyledSpace />
            <StyledSelectLabel>{translations.menu.selectDistance}</StyledSelectLabel>
            <DistanceSelector user={user} />
          </StyledMenuItem>
          <StyledMenuItem>
            <MenuItemHeader
              icon={<StyledShakeHandsIcon />}
              title={translations.cookieNotice.header}
            />
            <StyledCookiesBody>{translations.cookieNotice.body}</StyledCookiesBody>
            <StyledLink
              target="_blank"
              rel="noopener noreferrer"
              href={`https://legal.here.com/${linkLang}/privacy/cookies`}
            >
              {translations.cookieNotice.moreInfo}
            </StyledLink>
            <div>
              <input
                id="input-cookies-enable"
                type="checkbox"
                checked={user.cookies.accept}
                onChange={handleSwitch}
                disabled={disableRequestType}
              />
              <label
                className="custom-switcher"
                htmlFor="input-cookies-enable"
                title={disableRequestType ? translations.menu.disabledRequestTypeInfo : undefined}
              >
                T
              </label>
              <span className="label-custom-switcher inverted big">
                {user.cookies.accept
                  ? translations.cookieNotice.enable
                  : translations.cookieNotice.disable}
              </span>
            </div>
            {!isEmpty(translations.menu.rememberState) && user.cookies.accept && (
              <StyledSwitchContainer>
                <input
                  id="input-remember-state"
                  type="checkbox"
                  checked={user.hasStateMemory}
                  onChange={handleSwitchStateMemory}
                  disabled={disableRequestType}
                />
                <label
                  className="custom-switcher"
                  htmlFor="input-remember-state"
                  title={disableRequestType ? translations.menu.disabledRequestTypeInfo : undefined}
                >
                  T
                </label>
                <span className="label-custom-switcher inverted big">
                  {translations.menu.rememberState}
                </span>
              </StyledSwitchContainer>
            )}
            <StyledSwitchContainer>
              <input
                id="input-access-logging"
                type="checkbox"
                checked={user.accessLogging}
                onChange={handleSwitchAccessLogging}
              />
              <label className="custom-switcher" htmlFor="input-access-logging">
                T
              </label>
              <span className="label-custom-switcher inverted big">
                {translations.menu.enableAccessLogging}
              </span>
            </StyledSwitchContainer>
          </StyledMenuItem>

          <StyledMenuItem>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={documentationTP}
              onClick={onClickHelp}
            >
              <MenuItemHeader icon={<StyledMailIcon />} title={translations.contact.linkTitle} />
            </a>
          </StyledMenuItem>
        </StyledBody>
      </StyledMenu>
    </>
  );
};

export default withTranslation(Menu);
