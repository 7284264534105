import React, { useCallback, useEffect, useState } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import Checkbox from '../../Form/Checkbox';
import ObjectiveFunctionsList from './ObjectiveFunctionsList';
import { enableObjectiveFunction } from '../../../utils/solverConfig/config';

const StyledObjectiveFunctionsContainer = styled.div({
  padding: '1rem',
});

const ObjectiveFunctions = ({
  objectiveFunctions,
  onChange,
  terminationCriteriaEnabled,
  isBeta,
  translations: {
    solverConfigLabels: { objectiveFunctionsLabels },
  },
}) => {
  const [objectiveFunctionsEnabled, setObjectiveFunctionsEnabled] = useState(
    objectiveFunctions.enabled,
  );
  const [balanceObjectivesEnabled, setBalanceObjectivesEnabled] = useState(
    objectiveFunctions.values?.some((item) => item.enabled && item.balance),
  );
  const [calculatedHeight, setCalculatedHeight] = useState('');

  const handleEnabledValueChange = useCallback(
    (val) => {
      if (!onChange) return;

      const toReturn = { enabled: val, values: objectiveFunctions.values };
      onChange(toReturn);
    },
    [onChange, objectiveFunctions],
  );

  const handleOnBalanceSwitchChange = useCallback((val) => {
    setBalanceObjectivesEnabled(val);

    if (val === false && objectiveFunctions.values) {
      const enabledBalance = objectiveFunctions.values.findIndex(
        (item) => item.balance && item.enabled,
      );
      if (enabledBalance !== -1) {
        const newVal = enableObjectiveFunction(objectiveFunctions.values, enabledBalance);
        onChange({ enabled: objectiveFunctionsEnabled, values: newVal });
      }
    }
  });

  const handleOnSwitchChange = useCallback(
    (val) => {
      setObjectiveFunctionsEnabled(val);
      handleOnBalanceSwitchChange(false);
      handleEnabledValueChange(val);
    },
    [onChange, objectiveFunctions],
  );

  const handleOnChangeObjectiveFunctions = useCallback(
    (newObjectiveFunctions) => {
      if (!onChange) return;

      const toReturn = { enabled: objectiveFunctionsEnabled, values: newObjectiveFunctions };
      onChange(toReturn);
    },
    [onChange, objectiveFunctionsEnabled],
  );

  const checkboxLabel = objectiveFunctionsLabels.switchLabel;

  useEffect(() => {
    let height = 28;
    if (terminationCriteriaEnabled) {
      if (balanceObjectivesEnabled) height = 43;
      else height = 34;
    } else if (balanceObjectivesEnabled) {
      height = 40;
    }
    setCalculatedHeight(`calc(100vh - ${height}rem)`);
  }, [balanceObjectivesEnabled, objectiveFunctionsEnabled, terminationCriteriaEnabled]);

  useEffect(() => {
    setObjectiveFunctionsEnabled(objectiveFunctions.enabled);
  }, [objectiveFunctions]);

  return (
    <StyledObjectiveFunctionsContainer data-testid="objectiveEnablerToggle">
      <Checkbox
        id="objective-functions-enabler"
        label={checkboxLabel}
        value={objectiveFunctionsEnabled}
        contrast
        onChange={handleOnSwitchChange}
      />
      {objectiveFunctionsEnabled && (
        <ObjectiveFunctionsList
          objectiveFunctions={objectiveFunctions.values}
          onChange={handleOnChangeObjectiveFunctions}
          height={calculatedHeight}
          isBalanceObjective={false}
          isBeta={isBeta}
        />
      )}
      {objectiveFunctionsEnabled && isBeta && (
        <Checkbox
          id="balance-objectives-enabler"
          label={objectiveFunctionsLabels.balanceSwitchLabel}
          value={balanceObjectivesEnabled}
          contrast
          onChange={handleOnBalanceSwitchChange}
        />
      )}
      {objectiveFunctionsEnabled && isBeta && balanceObjectivesEnabled && (
        <ObjectiveFunctionsList
          objectiveFunctions={objectiveFunctions.values}
          onChange={handleOnChangeObjectiveFunctions}
          height={calculatedHeight}
          isBalanceObjective
          isBeta={isBeta}
        />
      )}
    </StyledObjectiveFunctionsContainer>
  );
};

export default withTranslation(ObjectiveFunctions);
