import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import withTranslation from '../../../hoc/withTranslation';
import { RETURN_LOCATION_MODE, setTourParameter } from '../../../actions';
import { StyledTextLight } from '../Global/WizardStyled';
import InputLocation from '../Global/InputLocation';
import Selector from '../../Form/Selector';
import Label from '../../Form/Label';

const StyledFleetEndAddress = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

const StyledExplanation = styled.div({
  marginTop: '0.5rem',
  marginBottom: '0.25rem',
});

function explanationText(tourPlanner, translations) {
  const {
    wizard: {
      fleet: {
        fleetReturnToStartLocation,
        fleetReturnToDistinctLocation,
        fleetReturnToEndLocation,
      },
    },
  } = translations;

  if (tourPlanner.returnLocationMode === RETURN_LOCATION_MODE.DISTINCT)
    return fleetReturnToDistinctLocation;
  if (tourPlanner.returnLocationMode === RETURN_LOCATION_MODE.END) return fleetReturnToEndLocation;

  return fleetReturnToStartLocation;
}

function FleetEndAddress({ oAuth, user, tourPlanner, translations }) {
  const {
    wizard: {
      fleet: {
        fleetReturnAddressLabel,
        fleetReturnAddressPlaceholder,
        fleetEndLocation: { startTrans, endTrans, distinctTrans },
      },
    },
  } = translations;
  const dispatch = useDispatch();
  const handleSetTourParameter = useCallback(
    (parameter) => dispatch(setTourParameter(parameter)),
    [dispatch],
  );

  const onLocationFound = useCallback(
    (returnLocation) => {
      handleSetTourParameter({ returnLocation });
    },
    [handleSetTourParameter],
  );

  const endLocation = useMemo(
    () => [
      { key: 'start', value: startTrans },
      { key: 'distinct', value: distinctTrans },
      { key: 'end', value: endTrans },
    ],
    [startTrans, endTrans, distinctTrans],
  );

  const onReturnLocationModeChange = useCallback(
    (value) => {
      const endLocationValue =
        value === 'start' ? { ...tourPlanner.location } : { label: '', value: null };
      handleSetTourParameter({ returnLocationMode: value, returnLocation: endLocationValue });
    },
    [tourPlanner],
  );

  useEffect(() => {
    if (
      tourPlanner.returnLocationMode === RETURN_LOCATION_MODE.START &&
      tourPlanner.location.value !== tourPlanner.returnLocation.value
    ) {
      const newLocation = tourPlanner.location.value
        ? { ...tourPlanner.location }
        : { value: null, label: '' };
      handleSetTourParameter({ returnLocation: newLocation });
    }
  }, [tourPlanner.location]);

  const locationInput =
    tourPlanner.returnLocationMode === 'distinct' ? (
      <InputLocation
        id="input-fleet-return-location"
        placeholder={fleetReturnAddressPlaceholder}
        value={tourPlanner.returnLocation.label}
        oAuth={oAuth}
        onLocationFound={onLocationFound}
        user={user}
        tourPlanner={tourPlanner}
      />
    ) : null;

  return (
    <StyledFleetEndAddress>
      <Label>{fleetReturnAddressLabel}</Label>
      <Selector
        id="button-end-location"
        initialValue={tourPlanner.returnLocationMode}
        options={endLocation}
        onChange={onReturnLocationModeChange}
      />
      <StyledExplanation>
        <StyledTextLight>{explanationText(tourPlanner, translations)}</StyledTextLight>
      </StyledExplanation>
      {locationInput}
    </StyledFleetEndAddress>
  );
}

export default withTranslation(FleetEndAddress);
