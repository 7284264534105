import { assign, cloneDeep, isEmpty, last, set } from 'lodash';
import { convertToGeoJSON } from 'utils/GeoJSONConverter';
import {
  CLEAR_SOLUTION,
  DELETE_SOLUTION,
  GET_SOLUTION,
  SET_PROBLEM,
  SET_PROBLEM_PARAMETER,
  SET_PROBLEM_JSON_PARAM,
  SET_SHOW,
  SET_JSON,
  SOLUTION_SET_PARAM,
  USER_SET_PARAM,
} from '../actions';
import { unclusterJobs } from '../utils/csv/clusters';
import { STORAGE_IDS } from '../utils/localStorageHelpers';
import {
  getStateFromMemory,
  setStateToMemory,
  shouldRememberLastState,
} from '../utils/MemoryHelpers';
import { isIterationSolution } from '../utils/SolutionHelpers';

const defaultState = {
  requests: [{ iterations: [], date: new Date(), timesPlanned: 0 }],
  show: 0,
  json: false,
  jsonUserChange: false,
  jsonPaste: false,
  counters: {
    manual: 1,
    import: 1,
    pasted: 1,
    demo: 1,
    problem: 1,
  },
};
const stateID = STORAGE_IDS.solution;
const stored = getStateFromMemory(stateID, defaultState);
if (stored && stored.iterations) {
  stored.requests[0] = {
    iterations: stored.iterations,
    date: new Date(),
  };
  stored.show = 0;
  delete stored.iterations;
}
const initialState = assign({}, cloneDeep(defaultState), stored);

const transformStateToStore = (state) => {
  if (
    !state ||
    !state.requests ||
    !state.requests[0].iterations ||
    !state.requests[0].iterations[0]
  )
    return state;
  return {
    counters: state.counters,
    requests: state.requests.map((request) => {
      const isAsync = request.statusHref || request.solutionHref;
      const lastIteration = last(request.iterations);
      return {
        ...request,
        iterations: isAsync ? [{ request: lastIteration.request }] : [lastIteration],
      };
    }),
  };
};

export default (state = cloneDeep(initialState), { payload = {}, type }) => {
  switch (type) {
    case SOLUTION_SET_PARAM: {
      const newState = cloneDeep(state);
      const index = payload.index !== undefined ? payload.index : newState.requests.length - 1;
      const iterationIndex = newState.requests[index].iterations.length - 1;
      const param = payload.param;
      if (param.runtime) {
        delete newState.requests[index].runtimeStart;
      }

      if (param.solutionHref) delete newState.requests[index].statusHref;
      if (payload.iterationParam) {
        newState.requests[index].iterations[iterationIndex] = assign(
          {},
          newState.requests[index].iterations[iterationIndex],
          param,
        );
      }
      const counter = shouldRememberLastState() && param.name && newState.counters[param.name];
      if (counter) {
        newState.requests[index] = {
          ...newState.requests[index],
          name: `${param.name}${counter}`,
        };
        newState.counters[param.name] = counter <= 100 ? counter + 1 : 1;
      } else {
        newState.requests[index] = assign({}, newState.requests[index], param);
      }

      setStateToMemory(stateID, newState, transformStateToStore);
      return newState;
    }
    case SET_SHOW: {
      const newState = cloneDeep(state);
      newState.show = payload;
      setStateToMemory(stateID, newState, transformStateToStore);
      return newState;
    }
    case SET_JSON: {
      const newState = assign({}, state, payload);

      setStateToMemory(stateID, newState, transformStateToStore);
      return newState;
    }
    case SET_PROBLEM: {
      const newState = cloneDeep(state);
      const request = newState.requests[payload.index ?? newState.requests.length - 1];
      request.iterations.push({
        request: payload,
        isProcessing: true,
      });
      setStateToMemory(stateID, newState, transformStateToStore);
      return newState;
    }
    case SET_PROBLEM_PARAMETER: {
      const newState = cloneDeep(state);
      const index = payload.index ?? newState.requests.length - 1;
      const lastIter = last(newState.requests[index].iterations);
      newState.requests[index].iterations.push({
        ...lastIter,
        request: { ...lastIter.request, ...payload.param },
      });
      return newState;
    }
    case SET_PROBLEM_JSON_PARAM: {
      const newState = cloneDeep(state);
      const lastIdx = newState.requests.length - 1;
      const jsonRequest = last(newState.requests[payload.index ?? lastIdx].iterations).request;

      if (jsonRequest) {
        set(jsonRequest.json, payload.path, payload.value);
        newState.requests[payload.index ?? lastIdx].iterations.push({ request: jsonRequest });
      }

      setStateToMemory(stateID, newState, transformStateToStore);
      return newState;
    }
    case GET_SOLUTION: {
      if (payload.error) {
        const newState = cloneDeep(state);
        newState.jsonUserChange = false;
        const lastRequest = newState.requests[newState.requests.length - 1];
        const errorIdx = newState.requests[payload.index];
        const lastIteration = lastRequest.iterations[lastRequest.iterations.length - 1];
        lastRequest.iterations = [lastIteration];
        if (errorIdx && payload.errorData)
          errorIdx.error = payload.errorData.cause ?? payload.errorData.error_description;
        setStateToMemory(stateID, newState, transformStateToStore);
        return newState;
      }

      const newState = cloneDeep(state);

      const hasIndex = payload.index !== undefined;
      const index = hasIndex ? payload.index : newState.requests.length - 1;
      const result = payload.solution || payload;

      const iterations = newState.requests[index].iterations;

      const hasNoIterations = state.requests[index].iterations.length === 0;
      const lastRequest = iterations[iterations.length - 1].request;

      const jobs = hasNoIterations || !lastRequest.json ? [] : lastRequest.json.plan.jobs;
      const orders = hasNoIterations || !lastRequest.orders ? [] : lastRequest.orders || [];
      const tourPlanner =
        hasNoIterations || !lastRequest.tourPlanner ? null : lastRequest.tourPlanner;
      const territories =
        hasNoIterations || !lastRequest.territories ? null : lastRequest.territories;
      const clusters = hasNoIterations || !lastRequest.clusters ? [] : lastRequest.clusters;

      const { data } = result;
      const unclustered = !isEmpty(clusters) ? unclusterJobs(data, clusters) : data;

      iterations[iterations.length - 1] = {
        ...iterations[iterations.length - 1],
        response: unclustered,
        geoJSON: convertToGeoJSON(
          unclustered,
          payload.stopsNoLocation,
          jobs,
          orders,
          tourPlanner,
          territories,
        ),
        isProcessing: false,
        stopsNoLocation: payload.stopsNoLocation,
      };

      newState.json = false;
      newState.jsonUserChange = false;
      newState.jsonPaste = false;

      setStateToMemory(stateID, newState, transformStateToStore);
      return newState;
    }
    case CLEAR_SOLUTION: {
      const newState = cloneDeep(state);
      const iterationsOfLastRequest =
        newState.requests[payload.index ?? newState.requests.length - 1].iterations;
      if (!isIterationSolution(last(iterationsOfLastRequest))) {
        newState.requests.pop();
      }
      if (
        newState.requests.length > 2 ||
        (!shouldRememberLastState() && newState.requests.length > 0)
      ) {
        newState.requests.shift();
      }
      const newRequest = {
        iterations: [{ request: iterationsOfLastRequest[0].request }],
        date: new Date(),
      };
      newState.requests.push(newRequest);
      newState.show = newState.requests.length - 1;
      setStateToMemory(stateID, newState, transformStateToStore);

      return newState;
    }
    case DELETE_SOLUTION: {
      const newState = cloneDeep(state);
      const iterationsOfLastRequest = newState.requests[newState.requests.length - 1].iterations;

      if (newState.requests.length === 1) {
        newState.requests.splice(payload.index, 1);
      }
      newState.requests = newState.requests.filter((n, idx) => !payload.index.includes(idx));

      if (newState.requests.length === 0) {
        const newRequest = {
          iterations: [{ request: iterationsOfLastRequest[0].request }],
          date: new Date(),
        };
        newState.requests.push(newRequest);
      }
      newState.show = newState.requests.length - 1;
      if (payload.index.includes(payload.current)) {
        newState.show = 0;
      }

      setStateToMemory(stateID, newState, transformStateToStore);
      return newState;
    }
    case USER_SET_PARAM: {
      if (payload.hasStateMemory === false) {
        const newState = { requests: [state.requests[state.requests.length - 1]], show: 0 };
        setStateToMemory(stateID, newState, transformStateToStore(), true);
        return newState;
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
