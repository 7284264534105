import moment from 'moment';
import 'moment-timezone';
import { v4 } from 'uuid';
import { ORDER_ACTIVITIES } from '../utils/csv/config';

export default {
  name: 'HERE Tour Planning',
  asset: {
    isExternal: process.env.REACT_APP_IS_EXTERNAL_ASSET || 'false',
    url: 'https://go.engage.here.com/rs/142-UEL-347/images/',
  },
  api: {
    betaUrl: process.env.REACT_APP_API_URL,
    prodUrl: process.env.REACT_APP_API_URL_PROD,
  },
  map: {
    url: `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?ppi=400&size=512&lang2=en&features=pois:disabled&apiKey=${process.env.REACT_APP_AK}`,
    pviewUrl: `https://maps.hereapi.com/v3/politicalViews?apikey=${process.env.REACT_APP_AK}`,
  },
  documentationLinks: {
    documentationBaseURL:
      'https://www.here.com/docs/bundle/tour-planning-api-developer-guide/page/topics/get-started/quick-start.html',
    documentationApiKeyURL:
      'https://www.here.com/docs/bundle/tour-planning-api-developer-guide/page/topics/get-started/quick-start.html#get-an-api-key',
    documentationRequestURL:
      'https://www.here.com/docs/bundle/tour-planning-api-developer-guide/page/topics/get-started/quick-start.html#send-a-request',
    documentationDevGuide: 'https://www.here.com/docs/bundle/tour-planning-api-developer-guide',
    documentationTP: 'https://developer.here.com/products/tour-planning',
  },
  geocoder: {
    url: 'https://geocode.search.hereapi.com/v1/geocode',
    autoSuggestUrl: 'https://geocode.search.hereapi.com/v1/autosuggest',
    discoverUrl: 'https://geocode.search.hereapi.com/v1/discover',
    reverseUrl: 'https://revgeocode.search.hereapi.com/v1/revgeocode',
  },
  languages: ['en', 'ja'],
  routing: {
    url: 'https://router.hereapi.com/v8/routes',
    characterCountLimit: 2000,
    routingUrlLength: 36,
  },
  requestTypes: {
    async: 'async',
    sync: 'sync',
    automatic: 'automatic',
  },
  oExe: {
    cla: process.env.REACT_APP_K,
    claSe: process.env.REACT_APP_S,
    url: 'https://account.api.here.com/oauth2/token',
  },
  splunk: {
    url: 'https://here.splunkcloud.com/en-US/app/foapi',
  },
  terra: {
    cla: process.env.REACT_APP_TERRA_K,
    claSe: process.env.REACT_APP_TERRA_S,
    claUrl: 'https://stg.account.api.here.com/oauth2/token',
    url: 'https://territoryplanning.hereapi.com/v1/areas',
  },
  share: {
    url: 'https://share.here.com/r',
  },
  ocr: {
    short: process.env.REACT_APP_OCR_K,
    long: process.env.REACT_APP_OCR_AK,
  },
  scenario: {
    times: [
      { key: 'day', value: { start: 8, end: 18 } },
      { key: 'morning', value: { start: 8, end: 11 } },
      { key: 'evening', value: { start: 19, end: 21 } },
    ],
    vehicle: {
      profile: 'car',
      amount: 2,
      capacity: [30],
      costs: {
        time: 7,
        distance: 0.5,
        fixed: 22,
      },
    },
    vehicleMobile: {
      profile: 'scooter',
      amount: 1,
      capacity: [30],
      costs: {
        time: 7,
        distance: 0.5,
        fixed: 22,
      },
    },
    start: 0,
    end: 24,
  },
  emptyProblem: {
    id: 'Unknown',
    plan: {
      jobs: [],
    },
  },
  defaults: {
    demand: 1,
    serviceTime: 300,
    cost: {
      duration: 0,
      distance: 0,
      fixed: 0,
    },
    solverConfiguration: {
      terminationCriteria: {
        maxTime: 30,
        stagnationTime: 5,
      },
    },
    limits: {
      maxDistance: 100000,
      shiftTime: 28800,
    },
  },
  shortTimeFormat: 'HH:mm',
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ssZ',
  shortDateTimeFormat: 'DD/MM/YYYY HH:mm',
  timezone: moment.tz.guess(),
  amplitudeApiKey: process.env.REACT_APP_AMP,
  maxValues: {
    orders: 250,
    capacity: 2147483647,
    vehicles: 350,
    demand: 2147483647,
    defaultVehicleCapacity: 6,
    costs: 100000,
    solverConfiguration: {
      terminationCriteria: {
        maxTime: 240,
        stagnationTime: 240,
      },
    },
    objectiveFunctionThreshold: 1,
    vehicleMaxDistanceLimit: 3000000,
    vehicleShiftTimeLimit: 86400,
  },
  minValues: {
    objectiveFunctionThreshold: 0,
    solverConfiguration: {
      terminationCriteria: {
        maxTime: 1,
        stagnationTime: 1,
      },
    },
  },
  limits: {
    vehicleTypesLimit: 35,
    jobsLimit: 500,
  },
  environment: process.env.REACT_APP_ENVIRONMENT,
  emptyOrder: {
    Address: '',
    Name: '',
    Demand: [1],
    'Service time (min)': 5,
    Latitude: null,
    Longitude: null,
    isEditing: true,
    InternalID: v4(),
    Phone: '',
    Activity: ORDER_ACTIVITIES.DELIVERY,
  },
};
