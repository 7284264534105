import {
  SET_TERRITORY_PARAMETER,
  AREA_CATEGORY,
  GET_AREAS,
  SET_TERRITORIES_FROM_PROBLEM_FILE,
  CLEAR_SOLUTION,
  DELETE_SOLUTION,
  GET_SOLUTION,
  CLEAR_ORDERS,
} from 'actions';
import { cloneDeep, assign } from 'lodash';
import { STORAGE_IDS, setStorageValue } from '../utils/localStorageHelpers';
import { getStateFromMemory, shouldRememberLastState } from '../utils/MemoryHelpers';
import { getValidOrdersForAreas, updateAreaDetails } from '../utils/territories/TerritoriesHelpers';

const defaultAreaCategory = AREA_CATEGORY.POSTAL;
const defaultTerritories = {
  isEnabled: false,
  bbox: null,
  areaCategory: defaultAreaCategory,
  areaDetails: null,
  selectedAreas: null,
  strict: true,
  fromUploadedFile: false,
  planned: null,
  csvPlan: false,
};
const defaultGroupAreas = {
  areaDetails: null,
};
const defaultAvoidAreas = {
  areaDetails: null,
};
const defaultTourHull = {
  areaDetails: null,
};
const defaultInitialState = {
  territories: defaultTerritories,
  groupAreas: defaultGroupAreas,
  avoidAreas: defaultAvoidAreas,
  tourHull: defaultTourHull,
};

const stored = getStateFromMemory(STORAGE_IDS.areas, [defaultInitialState]);
const initialState = assign([], cloneDeep([defaultInitialState]), stored);

export const filterValidAreas = (state) => {
  return state.filter(
    (f) =>
      f.avoidAreas.areaDetails !== null ||
      f.groupAreas.areaDetails !== null ||
      f.territories.areaDetails !== null ||
      f.territories.csvPlan,
  );
};

export default (state = cloneDeep(initialState), { payload = {}, type }) => {
  switch (type) {
    case SET_TERRITORY_PARAMETER: {
      const newState = cloneDeep(state);
      const index = payload.index !== undefined ? payload.index : newState.length - 1;

      newState[index].territories = {
        ...newState[index].territories,
        ...payload,
      };

      setStorageValue(STORAGE_IDS.areas, newState);
      return newState;
    }
    case GET_AREAS: {
      const newState = cloneDeep(state);
      newState[newState.length - 1].territories = {
        ...state[state.length - 1].territories,
        ...payload,
      };
      setStorageValue(STORAGE_IDS.areas, newState);
      return newState;
    }
    case CLEAR_SOLUTION: {
      const newState = cloneDeep(state);
      if (shouldRememberLastState()) {
        newState.push(defaultInitialState);
        return newState;
      }

      return state[state.length - 1].territories.fromUploadedFile ? [defaultInitialState] : state;
    }
    case DELETE_SOLUTION: {
      let newState = cloneDeep(state);
      if (shouldRememberLastState()) {
        newState = newState.filter((f, idx) => !payload.index.includes(idx));
        if (newState.length === 0) newState.push(defaultInitialState);
        return newState;
      }

      return state[state.length - 1].territories.fromUploadedFile ? [defaultInitialState] : state;
    }
    case CLEAR_ORDERS: {
      const newState = cloneDeep(state);

      if (newState[newState.length - 1].territories.fromUploadedFile && !payload.error)
        newState[newState.length - 1] = defaultInitialState;

      return newState;
    }
    case GET_SOLUTION: {
      if (payload.error) return state;

      const newState = cloneDeep(state);

      const hasIndex = payload.index !== undefined;
      const index = hasIndex ? payload.index : newState.length - 1;

      const { groupAreas, territories } = newState[index];

      if (!groupAreas.areaDetails && !territories.areaDetails) return state;

      const { data } = payload.solution || payload;
      const filteredOrders = getValidOrdersForAreas(
        data.tours,
        groupAreas.areaDetails,
        territories.areaDetails,
      );

      newState[index].groupAreas.areaDetails = updateAreaDetails(
        groupAreas.areaDetails,
        filteredOrders,
      );
      newState[index].territories.areaDetails = updateAreaDetails(
        territories.areaDetails,
        filteredOrders,
      );

      setStorageValue(STORAGE_IDS.areas, newState);

      return newState;
    }
    case SET_TERRITORIES_FROM_PROBLEM_FILE: {
      let newState = cloneDeep(state);
      const defaults = {
        ...defaultTerritories,
        isEnabled: true,
        fromUploadedFile: true,
        strict: false,
      };
      const { territories = [], avoidAreas = [], groupAreas = [], tourHull = [] } = payload;
      if (!shouldRememberLastState()) newState.pop();
      else newState = newState.filter((f) => f.territories.planned || f.territories.csvPlan);

      if (payload.index !== undefined && newState.length !== 0) {
        if (!newState[payload.index]) newState[payload.index] = defaultInitialState;
        newState[payload.index].tourHull.areaDetails = tourHull;
        newState[payload.index].territories.areaDetails = territories;
        newState[payload.index].avoidAreas.areaDetails = avoidAreas;
        newState[payload.index].groupAreas.areaDetails = groupAreas;
      } else {
        newState.push({
          territories: {
            ...defaults,
            areaDetails: territories,
            planned: false,
          },
          avoidAreas: {
            ...defaultAvoidAreas,
            areaDetails: avoidAreas,
          },
          groupAreas: {
            ...defaultGroupAreas,
            areaDetails: groupAreas,
          },
          tourHull: {
            ...defaultTourHull,
            areaDetails: tourHull,
          },
        });
      }

      if (newState.length === 0) newState.push(defaultInitialState);

      return newState;
    }
    default:
      return state;
  }
};
