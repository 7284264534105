import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import { findIndex, map, noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const {
  black,
  primaryColor,
  secondaryColor,
  transparentGreyColor,
  disabledBackground,
  aqua,
  white,
} = colors;
const { medium } = fontSize;

const StyledSelector = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: stretch;

  div:first-of-type {
    border-radius: 2rem 0 0 2rem;
    margin-right: 0.0625rem;
  }
  div:last-child {
    border-radius: 0 2rem 2rem 0;
    margin-left: 0.0625rem;
  }
  div:not(:first-of-type):not(:last-child) {
    margin: 0 0.0625rem;
  }
`;

const StyledButton = styled.div(
  {
    flex: '1',
    padding: '0',
    lineHeight: '2.5rem',
    textAlign: 'center',
    borderRight: 'none',
    transition: 'all 250ms cubic-bezier(0.600, 0.005, 0.320, 1.000)',
    span: {
      fontSize: medium,
    },
  },
  ({ isActive, isDisabled, inverted, whiteText }) => ({
    backgroundImage: isActive && `linear-gradient(to right, ${secondaryColor}, ${primaryColor})`,
    backgroundColor: isActive
      ? 'transparent'
      : isDisabled
      ? disabledBackground
      : transparentGreyColor,
    cursor: isActive ? 'default' : isDisabled ? 'not-allowed' : 'pointer',
    color: whiteText && white,
    fontSize: '.9rem',
    border: inverted ? `0.0625rem solid${primaryColor}` : 'none',
    fontWeight: !isActive && inverted && 300,

    ':hover': {
      backgroundColor: !isDisabled && !inverted ? '#E8E8E8' : 'auto',
      span: {
        color: !isDisabled ? (inverted ? aqua : black) : 'auto',
        transition: 'all 250ms cubic-bezier(0.600, 0.005, 0.320, 1.000)',
      },
    },
  }),
);

const Selector = ({
  className,
  initialValue,
  options = [],
  onChange = noop,
  id,
  disabledIndexes = [],
  inverted,
}) => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (initialValue) {
      const initialIndex = findIndex(options, ({ key }) => initialValue === key);
      setActive(initialIndex !== -1 ? initialIndex : 0);
    } else {
      setActive(-1);
    }
  }, [initialValue, options]);

  const onClick = useCallback(
    (index, key) => () => {
      if (disabledIndexes.includes(index)) return;

      setActive(index);
      onChange(key);
    },
    [onChange, setActive, disabledIndexes],
  );

  const optionsComponent = useMemo(
    () =>
      map(options, ({ key, value }, index) => (
        <StyledButton
          id={`${id}-${value.replace(' ', '-')}`}
          whiteText={id === 'button-type-select'}
          isActive={index === active}
          key={key}
          isDisabled={disabledIndexes.includes(index)}
          onClick={index !== active ? onClick(index, key) : noop}
          inverted={inverted}
        >
          {index === active ? <strong>{value}</strong> : <span>{value}</span>}
        </StyledButton>
      )),
    [active, onClick, options, id, disabledIndexes, inverted],
  );

  return <StyledSelector className={className}>{optionsComponent}</StyledSelector>;
};

export default Selector;
