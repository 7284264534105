import React, { useCallback, useEffect, useState } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { convertCostsFromTourPlanner } from 'utils/cost';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledFullWidthContainer,
  StyledWizardContent,
  StyledWizardH1,
  StyledWizardH2,
} from '../Global/WizardStyled';
import Stops from './Stops';
import Label from '../../Form/Label';
import {
  getDemand,
  getLastValidIteration,
  getLoadPercentage,
  getVehicleTypeById,
  isDemandVisible,
} from '../../../utils/SolutionHelpers';
import TourDispatch from '../TourDispatch/TourDispatch';
import { convertToDisplayTime, isMobileDevice } from '../../../utils/helpers';
import { getCSVConfigLastDemandLabel } from '../../../utils/csv/customConfig';
import { getSafeValue } from '../../../utils/security';
import { StyledTourDispatcherContainer } from '../Wizard';
import { getRoutingTourInfo, selectTourById } from '../../../actions';
import { tourToRoutingRequest } from '../../../utils/RoutingConverter';
import TourDetails from './TourDetails';

const StyledLabelContainer = styled.div({
  width: '50%',
});

const Step4Container = ({
  user,
  tourData: { fleet, plan, tours = [] },
  selectedTours,
  solution,
  tourPlanner,
  orders,
  helpers: { formatDuration },
  onSearchId,
  oAuth,
  translations,
}) => {
  if (tours.length === 0) {
    return <></>;
  }
  const dispatch = useDispatch();
  const handleSelectTourById = useCallback((data) => dispatch(selectTourById(data)), [dispatch]);
  const handleGetRoutingTourInfo = useCallback(
    (index, tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest(tour, tourPlanner, orders),
          tourId: index,
          solutionId: solution.show,
        }),
      ),
    [dispatch, oAuth, tourPlanner, solution.show, orders],
  );
  const {
    display: { selectedTour },
  } = useSelector(({ mapContainer }) => mapContainer);
  const [demandLabel, setDemandLabel] = useState('');
  const [isDemandVisibleLocal, setIsDemandVisibleLocal] = useState('');
  const {
    wizard: {
      tours: { vehicleLabel, runTimeTrans },
      toursDetails: { toursDetailsTitle, dropsTrans },
    },
  } = translations;

  useEffect(() => {
    const demandVisible = isDemandVisible(orders, tourPlanner);
    let label = '';
    if (demandVisible && !isMobileDevice()) {
      label = getCSVConfigLastDemandLabel(tourPlanner, 'demand', dropsTrans);
    }

    setIsDemandVisibleLocal(demandVisible);
    setDemandLabel(label);
  }, [orders, tourPlanner, dropsTrans, setDemandLabel, setIsDemandVisibleLocal]);

  const tourId = selectedTour ?? 0;
  const tour = getSafeValue(tours, tourId);
  const isImperial = user.distance === 'imperial';
  const formattedDetails = convertCostsFromTourPlanner({
    distance: tour.statistic.distance,
    isImperial,
  });
  const runtime = solution.requests[solution.show].runtime;
  const formattedDuration = tour.statistic.duration
    ? formatDuration(Math.floor(tour.statistic.duration / 60))
    : 'Unknown';
  const vehicleType = getVehicleTypeById(tour.typeId, fleet);
  const co2Emission = tour.statistic?.co2Emission?.toFixed(2);
  const consumption = tour.statistic?.consumption?.toFixed(2);

  const totalDetails = {
    distance: formattedDetails.distance ? formattedDetails.distance.toFixed(2) : 0,
    time: formattedDuration,
    cost: tour.statistic.cost.toFixed(2),
    co2Emission: co2Emission || '',
    consumption: consumption || '',
  };
  const request = getLastValidIteration(solution.requests[solution.show]).request;
  const vehicleTerritories = vehicleType?.territories?.items.map((t) => t.id);
  const territories = vehicleTerritories?.length > 0 && vehicleTerritories.join(', ');
  const skills = vehicleType?.skills?.length > 0 && vehicleType.skills.join(', ');
  const tourDemand = getDemand({ tour, plan });
  const tourLoad = getLoadPercentage(tourDemand, vehicleType);
  const changeUiPosition = tourLoad && isDemandVisibleLocal;
  const smallerUi =
    territories ||
    skills ||
    (changeUiPosition && (totalDetails.consumption || totalDetails.co2Emission));

  useEffect(() => {
    handleSelectTourById({ selectedTour: tourId });
    handleGetRoutingTourInfo(tourId, tour);
  }, [tour]);

  return (
    <StyledWizardContent data-test-id="tourDetailsPanel">
      <StyledWizardH1 noMargin marginTop>
        {toursDetailsTitle}
      </StyledWizardH1>
      <StyledWizardH2 noMargin marginTop>
        {`${runTimeTrans}: ${convertToDisplayTime(runtime)}`}
      </StyledWizardH2>
      <StyledLabelContainer>
        <Label>{`${vehicleLabel}: ${tour.vehicleId}`}</Label>
      </StyledLabelContainer>
      {tour && request && (
        <StyledTourDispatcherContainer step4>
          <TourDispatch
            user={user}
            selectedTours={selectedTours}
            solution={solution}
            tourPlanner={tourPlanner}
            tour={tour}
            request={request}
            origin="tour details"
            openIdSearch={() => onSearchId()}
            fleet={fleet}
            oAuth={oAuth}
            orders={orders}
          />
        </StyledTourDispatcherContainer>
      )}
      <TourDetails
        user={user}
        tours={tours}
        tourPlanner={tourPlanner}
        demandLabel={demandLabel}
        isDemandVisibleLocal={isDemandVisibleLocal}
        totalDetails={totalDetails}
        territories={territories}
        skills={skills}
        vehicleType={vehicleType}
        tourLoad={tourLoad}
        tourDemand={tourDemand}
      />
      <StyledFullWidthContainer>
        <Stops
          stops={tour.stops}
          request={request}
          tourPlanner={tourPlanner}
          user={user}
          demandLabel={demandLabel}
          isDemandVisible={isDemandVisibleLocal}
          tourId={tourId}
          typeId={tour.typeId}
          smaller={smallerUi}
        />
      </StyledFullWidthContainer>
    </StyledWizardContent>
  );
};

export default withTranslation(Step4Container);
