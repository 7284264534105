import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { colors, fontSize } from '../../../global/variables';
import UnassignedNotices from './UnassignedNotices';

export const StyledMainDetail = styled.div(({ light }) => ({
  lineHeight: '1rem',
  padding: '0.2rem',
  color: colors.secondaryTextColor,
  fontSize: fontSize.small,
  backgroundColor: colors.white,
  paddingBottom: light ? '0.1rem' : '0.5rem',
  fontWeight: light ? '500' : '700',
}));

const StyledOrderTitle = styled.div(({ isClickable }) => ({
  cursor: isClickable && 'pointer',
}));

const StyledOrderDetails = styled.div(({ displayInfo }) => ({
  height: displayInfo ? 'auto' : 0,
  overflow: 'hidden',
  fontWeight: '400',
  transition: 'all 3s ease-out',
  padding: '0.2rem 0 0 0',

  div: {
    margin: '0.2rem',
  },
}));

const UnassignedOrder = ({ unassignedOrder, showDetail, onClick, notices, noticesTrans }) => {
  const handleOnClick = useCallback(() => {
    if (onClick) onClick(unassignedOrder);
  }, [unassignedOrder, onClick]);

  if (!unassignedOrder) return null;

  const isClickable = !!unassignedOrder.Name && unassignedOrder.Address !== unassignedOrder.Name;

  return (
    <StyledMainDetail onClick={handleOnClick}>
      <StyledOrderTitle isClickable={isClickable}>{unassignedOrder.Address}</StyledOrderTitle>
      {notices && (
        <UnassignedNotices
          unassignedOrder={unassignedOrder}
          notices={notices}
          noticesTrans={noticesTrans}
        />
      )}
      <StyledOrderDetails displayInfo={showDetail && isClickable}>
        <div>{unassignedOrder.Name}</div>
        <div>{unassignedOrder.ID}</div>
      </StyledOrderDetails>
    </StyledMainDetail>
  );
};

export default UnassignedOrder;
