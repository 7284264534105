import React, { useCallback, useEffect } from 'react';
import withTranslation from 'hoc/withTranslation';
import { size, isEmpty, keys } from 'lodash';
import { useDispatch } from 'react-redux';
import { convertToDisplayTime } from 'utils/helpers';
import {
  StyledWizardContent,
  StyledWizardH1,
  StyledFullWidthContainer,
  StyledText,
  StyledWizardH2,
} from '../Global/WizardStyled';
import Tours from './Tours';
import Section from '../../Form/Section';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { getTotalJobsInTours } from '../../../utils/SolutionHelpers';
import SummaryWithDownload from './SummaryWithDownload';
import UnassignedList from './UnassignedList';
import { selectUnassigned } from '../../../actions';
import { getSafeValue, setSafeValue } from '../../../utils/security';
import SimpleButton from '../../Form/SimpleButton';
import iconSearch from '../../../global/img/search.svg';
import { StyledActions, StyledTourDispatcherContainer } from '../Wizard';

const Step3Container = ({
  user,
  oAuth,
  tourData,
  onTourChange,
  selectedTours,
  sharedTours,
  solution,
  orders,
  tourPlanner,
  display,
  onSearchId,
  translations: {
    wizard: {
      tours: { toursTitle, noTours, runTimeTrans, detailsTitleTrans },
    },
  },
}) => {
  const dispatch = useDispatch();
  const runtime = solution.requests[solution.show].runtime;
  const handleSelectUnassigned = useCallback(
    (show) => dispatch(selectUnassigned(show)),
    [dispatch],
  );

  useEffect(() => {
    const slnInfo = {
      assignedOrders: getTotalJobsInTours(tourData.tours),
      unassignedOrders: size(tourData.unassigned),
      totalTours: size(tourData.tours),
      totalDuration: tourData.statistic.duration,
      totalDistance: tourData.statistic.distance,
      totalCost: tourData.statistic.cost,
    };

    if (!isEmpty(tourData.unassigned)) {
      const grouped = {};
      tourData.unassigned.forEach((item) => {
        item.reasons.forEach((reason) => {
          if (!getSafeValue(grouped, reason.code)) setSafeValue(grouped, reason.code, []);
          getSafeValue(grouped, reason.code).push(item);
        });
      });
      keys(grouped).forEach((key) => {
        setSafeValue(slnInfo, `unassignedOrdersCode${key}`, size(getSafeValue(grouped, key)));
      });
    }

    AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_OVERVIEW, slnInfo);
  }, [tourData]);

  const handleOnChangeView = useCallback((viewTours) => {
    handleSelectUnassigned(!viewTours);
  });

  const preventTextSelection = (event) => {
    if (event.shiftKey) {
      event.preventDefault();
    }
  };

  return (
    <StyledWizardContent sticky data-test-id="tourOverviewPanel">
      <StyledWizardH1>{toursTitle}</StyledWizardH1>
      <StyledWizardH2 noMargin marginTop>
        {`${runTimeTrans}: ${convertToDisplayTime(runtime)}`}
      </StyledWizardH2>
      {tourData.tours.length > 0 && (
        <>
          <Section>
            <StyledTourDispatcherContainer searchOnly>
              <StyledActions searchOnly>
                <SimpleButton
                  id="button-search-Job"
                  icon={iconSearch}
                  onClick={() => onSearchId()}
                  smaller
                  noMargin
                />
              </StyledActions>
            </StyledTourDispatcherContainer>
          </Section>
          <Section>
            <StyledText detailsTitle>{detailsTitleTrans}</StyledText>
            <SummaryWithDownload
              lastIterationTourData={tourData}
              user={user}
              tourPlanner={tourPlanner}
              orders={orders}
              solution={solution}
              isToursView={!display.showUnassigned}
              onChangeView={handleOnChangeView}
            />
          </Section>
          {!display.showUnassigned ? (
            <StyledFullWidthContainer
              sticky
              onMouseDown={preventTextSelection}
              onMouseUp={preventTextSelection}
            >
              <Tours
                id="solutionTours"
                oAuth={oAuth}
                lastIterationTourData={tourData}
                onTourChange={onTourChange}
                selectedTours={selectedTours}
                sharedTours={sharedTours}
                solution={solution}
                user={user}
                tourPlanner={tourPlanner}
                display={display}
              />
            </StyledFullWidthContainer>
          ) : (
            <UnassignedList
              lastIterationTourData={tourData}
              orders={orders}
              oAuth={oAuth}
              tourPlanner={tourPlanner}
              solution={solution}
            />
          )}
        </>
      )}
      {tourData.tours.length === 0 && (
        <>
          <Section>
            <StyledText>{noTours}</StyledText>
          </Section>
          <Section>
            <UnassignedList
              lastIterationTourData={tourData}
              orders={orders}
              oAuth={oAuth}
              tourPlanner={tourPlanner}
              solution={solution}
              noTours
            />
          </Section>
        </>
      )}
    </StyledWizardContent>
  );
};

export default withTranslation(Step3Container);
